import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerabout"

const AboutUs = () => (
  <Layout>
    <SEO title="About us" />
    <Header />
    <section className="section bg-primary">
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <h1 className="h2 my-2 font-weight-bold text-light">
              ABOUT STUDY IN RWANDA
            </h1>
          </div>
          <div className="col-auto">
            <a className="btn btn-outline-light">OUR VISION</a>
          </div>
        </div>
      </div>
    </section>
    <section className="section bg-dark">
      <div className="container-fluid py-4">
        <div className="py-4">
          <ul className="nav nav-fill">
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                About Rwanda
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                Why invest in Rwanda?
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                Our mission
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                Our vision
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                Our Goals
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#">
                Study in Rwanda Leadership
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="section py-8 px-8 pb-5">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-6 order-lg-2">
              <h5 className="h1 font-weight-bold text-primary">About Rwanda</h5>
              <p className="font-size-lg my-4">
                Welcome to the Singapore of Africa and pride of East Africa!
                Rwanda is a beautiful landlocked country in central Africa. Its
                scenic features enhance its beauty. Rwanda is also referred to
                as the land of a thousand hills. It is bordered Uganda to the
                North, Tanzania to the east, Democratic republic of Congo to the
                West and Burundi to the south. Rwanda is home to several tourist
                sites.
              </p>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/AboutUs.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-5 mr-lg-5">
              <h5 className="h1 font-weight-bold text-primary">Why invest in Rwanda?</h5>
              <p className="font-size-lg my-4">
                <ul>
                  <li>Sustained high economic growth</li>
                  <li>Effective governance</li>
                  <li>Excellent labor market</li>
                  <li>Access to market</li>
                  <li>Investor friendly climate</li>
                  <li>Stability and reasonable predictability</li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6">
              <div className="card mb-0 ">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/DJI_0171.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section py-4 font-size-lg px-8 pb-9">
      <div className="container-fluid">
        <div className="py-4">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-6 order-lg-2">
              <p className=" my-4">
                Rwanda has been identified as one of the easiest country to do
                business in the East African region. Rwanda’s GDP growth rate is
                also one of the highest among African economies and its
                neighboring countries. In a market population of over 12 million
                people, there has been rapid growth of the middle class. The
                country has become a point of reference for good governance in
                East Africa. Its political stability has promoted conducive
                environment for our institutions.
              </p>
              <p className=" my-4">
                Rwanda also has zero corruption tolerance and Law-abiding
                citizens. Its rich history and culture are also useful tools of
                education. For this reason, we encourage international
                organizations to consider Rwanda as a suitable place for
                investment.
              </p>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/DSC_2244.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className="h1 font-weight-bold text-primary text-center">About us</h5>
              <p className="my-4">
                <p class="text-primary h3 font-weight-bolder"> Our mission </p> To promote international education services in Africa through recruitment programs, quality education with worldwide accreditation focusing mainly on the African market.
              </p>
              <p className="mt-4">
                <p class="text-primary h3 font-weight-bolder"> Our Vision </p> To make Rwanda the best study destination in Africa, promoting international tertiary education through investment in African research-led universities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section font-size-lg ">
      <div className="container-fluid">
        <div className="py-2">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-10">
              <h5 className="h3 font-weight-bold text-primary">Our goals</h5>
              <p className="mb-4">
                <ul>
                  <li>
                    To act as a national and international guide to higher
                    education in Africa
                  </li>
                  <li>
                    To lead Africa’s digital learning and technology revolution
                  </li>
                  <li>
                    Being a one-stop source for educational services in Africa
                  </li>
                  <li>
                    To brand the education sector in Rwanda while promoting
                    international standards
                  </li>
                </ul>
              </p>
            </div>
            {/* <div className="col-lg-6">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                  
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-10 order-lg-2">
              
            <img
                    alt="Image placeholder"
                    src={require("../images/claude.jpg")}
                    className="img-thumbnail mw-50 rounded mx-auto d-block"
                  />
              <h5 className="h3 text-primary text-center m-4 font-weight-bold">
                STUDY IN RWANDA LEADERSHIP
              </h5>
              <p className=" my-4">
                <b>Mr. Claude Gakwandi</b> is the founder and president of the <b>Study in Rwanda program</b>. Gakwandi is an entrepreneur in the education sector and a creative educator who supports the international education in Rwanda and around the world by providing opportunities for international experiences in the education system.
              </p>
              <p className=" my-4">
              He is driven by the belief that in a world of instant gratification, the new generation needs to understand that education has no shortcuts. There is need for patience and will to strive for excellence. As the founder and Chairman of ‘’Study in Rwanda Program’’ his greatest satisfaction comes from seeing students realize full potential and talents through international learning experiences. Through his educational innovative programs, curriculum and consultancies, Gakwandi ensures he gives equal opportunities and guidance in a very relatable way, regardless of the field of study, or job title.
              </p>
              <p className=" my-4">
              ‘’Claude Gakwandi’’ is also the founder and Manager of Center for Education Network <b>(CEN) www.cenrwanda.org</b> .  His work has been awarded across numerous platforms. 
              </p>
              <p className=" my-4">
              <b>In 2003</b>, Claude Gakwandi initiated <b>‘’Study in Europe’’</b> especially in Italy where he recruited students to learn sciences and engineering programs languages at an affordable cost. 
              </p>
              <p className=" my-4">
              <b>In the year in 2004</b> Gakwandi initiated Study in Canada where he recruited many students from east Africa and Rwandan student to study in Canada. He has been working with Canadian universities across to Africa, supporting Canadian universities to organize education fair and helping education investors to raise their profiles in Rwanda. 
              </p>
              <p className=" my-4">
              <b>In the year 2006 to 2007</b>, Mr. Gakwandi felt the need to increase opportunities to study abroad. He founded several initiatives which include: He initiated Study in Ireland, Study in Cyprus and Study in UK which was successfully functional for 2 years. In the same year, Claude Gakwandi introduced a Study in Turkey initiative, with the purpose of giving the Muslim community an opportunity to study in Turkey being a Muslim country. Turkey is known as a beautiful country with modern rich culture. 
              </p>
              <p className=" my-4">
              <b>From 2008-2013 Mr.Gakwandi</b> created new study destination market which are Study in Poland, Mauritius and Australia for the purpose to promote education research, medicine and agriculture programs made a research tour where he travelled all over Africa in search for solutions facing the education system in Africa. His determination led him back to Rwanda where he started working towards a program that would bring people together from all over the world to experience Rwanda as a place to study and tour.  
              </p>
              <p className=" my-4">
              Gakwandi Claude is the chairperson of Study in Africa and Chairman of "Study in Rwanda International Program"
              </p>
              <p className=" my-4">
              <b>Mr. Gakwandi Claude</b> is determined to grow the international education Industry in Rwanda and very honored to support it’s vision in partnership with government bodies, local and international institutions all over the world. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
